import { AxiosResponse } from "axios"
import api from "."
import { resolveIpfs } from "../utils"

export const getNfts = async (
  walletAddress: string
): Promise<
  AxiosResponse<{
    result: {
      name: string
      image: string
    }[]
  }>
> => {
  const res = await api.get(`/users/${walletAddress}/nfts`)
  const collection = res.data.result
    .filter(data => data.metadata != null)
    .map((data: any) => {
      const metadata = JSON.parse(data.metadata)
      if (!metadata) {
        return {
          tokenHash: data.token_hash,
          name: "Untitled",
          image: "#",
        }
      }

      return {
        tokenHash: data.token_hash,
        name: metadata.name,
        image: resolveIpfs(metadata.image),
      }
    })

  return {
    ...res,
    data: {
      ...res.data,
      result: collection,
    },
  }
}
