import { Container, Flex, Heading, Spinner, Text } from "@chakra-ui/react"
import { AxiosError } from "axios"
import { graphql, navigate } from "gatsby"
import * as React from "react"
import { useTranslation } from "react-i18next"
import { getNfts } from "../../api/nfts"
import { useQuery } from "@tanstack/react-query"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import { isContractAddress } from "../../utils"
import NftListView from "../../components/NftListView"

const NFTCollectionPage = ({ params }) => {
  const { t } = useTranslation()
  const { walletAddress } = params

  const { status, data, isFetched, isFetching } = useQuery(
    ["nft-collection", walletAddress],
    async () => {
      if (walletAddress == null) return null

      if (!isContractAddress(walletAddress)) {
        navigate("/404")
        return null
      }
      const res = await getNfts(walletAddress)
      return res.data.result
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      onError: (err: any) => {
        if (err instanceof AxiosError && err.response?.status === 404) {
          navigate("/404")
        }
        console.log(err)
      },
    }
  )

  if (!isFetched) {
    return (
      <Layout>
        <Container textAlign="center" py="32">
          <Flex my="28" justifyContent="center" alignItems="center">
            <Spinner size="md" />
          </Flex>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout>
      <SEO title={t("title") ?? "NFT Collection"} />
      <Container textAlign="center" py="32">
        <Heading mt="4">{t("title")}</Heading>
        <Text color="gray.400" fontSize="sm" my="2">
          {walletAddress}
        </Text>
        <NftListView isLoading={isFetching} list={data as any} />
      </Container>
    </Layout>
  )
}

export default NFTCollectionPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "nft_collection"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
