import { Flex, Grid, Spinner, useBreakpointValue } from "@chakra-ui/react"
import { BigNumber } from "ethers"
import React, { FC } from "react"
import NFTListItem from "../components/NFTListItem"

interface NftListViewProps {
  isLoading: boolean
  price?: BigNumber
  list?: any[]
  onClickItem?: () => void
}

const NftListView: FC<NftListViewProps> = ({
  isLoading,
  price,
  list,
  onClickItem,
}) => {
  const templateColumns = useBreakpointValue({
    sm: "repeat(2, 1fr)",
    md: "repeat(3, 1fr)",
    lg: "repeat(5, 1fr)",
  })

  if (isLoading) {
    return (
      <Flex p="16" my="16" justifyContent="center">
        <Spinner />
      </Flex>
    )
  }
  if (!list) return null

  return (
    <Grid gap={6} my="16" templateColumns={templateColumns}>
      {list.map(nft => (
        <NFTListItem
          key={nft.tokenHash}
          price={price}
          nft={nft}
          onClick={onClickItem}
        />
      ))}
    </Grid>
  )
}

export default NftListView
