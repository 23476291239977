import React from "react"
import { Box, GridItem, Img, Text } from "@chakra-ui/react"
import { BigNumber } from "ethers"
import { FC } from "react"

interface NFTListItemProps {
  price?: BigNumber
  nft: {
    name: string
    image: string
  }
  onClick?: () => void
}

const NFTListItem: FC<NFTListItemProps> = ({ price, nft, onClick }) => {
  return (
    <GridItem
      w="230px"
      bg="gray.100"
      borderRadius="10"
      m="auto"
      onClick={onClick}
    >
      <Box
        className="nft-container"
        boxShadow="rgb(0, 0, 0, 8%) 0px 4px 15px"
        borderRadius="10"
        cursor="pointer"
      >
        <Box
          overflow="hidden"
          height="220px"
          width="230px"
          borderTopLeftRadius="10"
          borderTopRightRadius="10"
        >
          <Img
            className="nft-item"
            src={nft.image}
            objectFit="cover"
            width="100%"
            height="100%"
          />
        </Box>
        <Box
          py="4"
          px="3"
          backgroundColor="white"
          borderBottomLeftRadius="10"
          borderBottomRightRadius="10"
        >
          <Text fontSize="xl" fontWeight="400" color="gray.600">
            {nft.name}
          </Text>
        </Box>
      </Box>
    </GridItem>
  )
}

export default NFTListItem
